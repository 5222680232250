html,
body {
  height: 100%;
  overflow: auto;
}

body::after {
  content: '';
  background: linear-gradient(30deg, #dab5d3, transparent);
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
